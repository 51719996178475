import React from "react";
// import { stackList } from "../../data/ProjectData";
import {
  Image,
  // Technologies,
  // Tech,
  // TechImg,
  // TechName,
  ContactWrapper,
} from "./AboutElements";
import ScrollAnimation from "react-animate-on-scroll";
function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">About Me</div>
        <div className="BigCard">
          <ScrollAnimation animateIn="fadeIn">
            <Image src="/man-svgrepo-com.png" alt="man-svgrepo" />
          </ScrollAnimation>
          <div className="AboutBio">
            <ScrollAnimation animateIn="fadeIn">
              Hey there, I'm <strong>Yadu Raju Kurup</strong>!
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeIn">
              I'm a mechanical engineer with a curious mind and a love for
              building things. While my background is in engineering, I also
              hold a Master's in Business from the University of Dundee, which
              helps me understand the bigger picture.
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeIn">
              Throughout my career, I've always been drawn to new challenges. I
              took a stab at blockchain with Contrackts, a project to improve
              supply chain tracking. Then, I tackled the confusing world of
              medical bills with Patient Pouch, an app to help people understand
              their healthcare costs.
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeIn">
              Now, I'm leading the charge at Wolfcorelabs, a robotics and AI
              startup. We're building technologies that make it easier for
              humans and machines to work together. It's an exciting time to be
              at the forefront of innovation, and I can't wait to see what the
              future holds!
            </ScrollAnimation>
            {/* <ScrollAnimation animateIn="fadeInLeft">
              Working collaboratively within a team towards a shared objective has been an incredibly rewarding and unique experience for me. I am eager to continue exploring exciting projects in the future, with a particular interest in web development and cross-platform mobile development.
              <div className="tagline2">
                I have become confident using the following technologies:
              </div>
            </ScrollAnimation> */}
            {/* <Technologies>
              {stackList.map((stack, index) => (
                <ScrollAnimation animateIn="fadeInLeft" key={index}>
                  <Tech key={index} className="tech">
                    <TechImg src={stack.img} alt={stack.name} />
                    <TechName>{stack.name}</TechName>
                  </Tech>
                </ScrollAnimation>
              ))}
            </Technologies> */}
          </div>
        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;
